import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, Observable, catchError, filter, switchMap, tap } from 'rxjs';
import { ToasterService } from '../../shared/utils/services/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Log, Provider } from '@webcoffee/interfaces';
import { DateTime } from 'luxon';

export interface ProviderLogsState {
    logs: Log[];
    query: { from: string; sku?: string; provider?: string };
    loading: boolean;
    providers: Provider[];
}

@Injectable()
export class ProviderLogsStore extends ComponentStore<ProviderLogsState> {
    constructor(
        private http: HttpClient,
        private toastr: ToasterService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        super({
            logs: [],
            query: { from: '15m' },
            loading: true,
            providers: [],
        });
    }

    readonly getLogs = this.select((state) => state.logs);
    readonly getQuery = this.select((state) => state.query);
    readonly getLoading = this.select((state) => state.loading);
    readonly getProviders = this.select((state) => state.providers);

    readonly updateQuery = this.updater((state, payload: any) => {
        const query = { ...state.query, ...payload };
        if (!query.sku || query.sku.trim() === '') delete query.sku;
        if (!query.provider || query.provider.trim() === '') delete query.provider;

        this.router.navigate([], { relativeTo: this.route, queryParams: query });

        return { ...state, query };
    });

    readonly getProviderLogsEffect = this.effect((params$: Observable<any>) =>
        params$.pipe(
            filter((params) => !!params && Object.keys(params)?.length !== 0),
            switchMap((params) => {
                this.patchState({ loading: true });
                let decodedParams = new HttpParams();
                for (const [queryKey, queryVal] of Object.entries(params)) {
                    if (typeof queryVal === 'string') {
                        decodedParams = decodedParams.append(queryKey, queryVal);
                    } else {
                        decodedParams = decodedParams.append(queryKey, JSON.stringify(queryVal));
                    }
                }

                return this.http.get<Log[]>('/api/providers/logs', { params: decodedParams }).pipe(
                    tap((logs) => this.patchState({ logs, loading: false })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        this.patchState({ loading: false });
                        return EMPTY;
                    }),
                );
            }),
        ),
    );

    readonly getProvidersEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get<Provider[]>('/api/providers', { params: { select: 'name' } }).pipe(
                    tap({
                        next: (providers) => this.patchState({ providers }),
                        error: (err) => this.toastr.error(err.error.message),
                    }),
                ),
            ),
        ),
    );
}
